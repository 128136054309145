<template>
  <div>
    <HeaderCustom></HeaderCustom>
    <div class="container" :class='$style.myContentsDiv'>
      <div class="row">
        <div class="col-md-4" v-if="categories.length > 0">
          <div :class="$style.divDdl">
            <select class="form-control" v-model="selectedCategory" v-html="categoriesOptString" :class="$style.combobox">
            </select>
          </div>
        </div>
        <div class="col-md-4" v-if="themes.length > 0">
          <div :class="$style.divDdl">
            <select class="form-control" v-model="selectedTheme" v-html="themesOptString" :class="$style.combobox">
            </select>
          </div>
        </div>
        <div v-if="categories.length > 0 && themes.length > 0" class="col-md-4">
          <div :class="$style.divDdl">
            <Search :class="$style.divSearch"></Search>
          </div>
        </div>
        <div v-else class="col-md-4 offset-md-4">
            <Search></Search>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h1 class="title menuTitle" style="font-weight: normal;" :class='$style.menuTitle'>{{title}}</h1>
          <hr>
        </div>
      </div>
      <div v-if="!ready">
          <InnerLoading></InnerLoading>
      </div>
      <div class="row" :class="$style.columns" v-else-if="scormData.length > 0">
        <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12" :class="$style.column" v-for="(item,i) in this.scormData" :key="i">
          <Card 
          :coverImageHeight='coverImgHeight' 
          contentType="scorm" 
          :id='item.id'
          :scormTitle='item.name' 
          :imgpath="item.big_image ? item.big_image : item.image"
          :scormCode='item.scorm_code' 
          :scormDesc='item.scorm_details1' 
          :packageId='item.collection'>
          </Card>
        </div>
        <div class="col-md-12" >
          <lmsPagination
            v-if="this.pagination && this.scormData.length > 0"
            :data="this.pagination"
            @goToPage="goToPage">
          </lmsPagination>
        </div>
      </div>
      <div v-else>
        <p :class="$style.noContent">There is no content here.</p>
      </div>

    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import HeaderCustom from '@/components/HeaderCustom';
  import InnerLoading from '@/components/InnerLoading';
  import lmsPagination from '@/components/pagination';
  import Card from '@/components/Card3';
  import Search from '@/components/Search';
  import services from '@/services/content';

  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'Library',
    data() {
      return {
        scormData: [],
        linkData: [],
        title: '',
        pagination: null,
        ready: false,
        themes: [],
        categories: [],
        themesOptString: '',
        categoriesOptString: '',
        selectedTheme: 0,
        selectedCategory: 0,
        searchText: '',
        coverImgHeight: null,
        searchData: null,
        grade: null,
        libraryShowClasses: [66283],
        gradeReady: false,
      };
    },
    components: {
      HeaderCustom,
      Card,
      Search,
      InnerLoading,
      lmsPagination,
    },
    computed: {
      ...mapState(['scorms', 'userDetails']),
    },
    async beforeMount() {
      // const collectionId = parseInt(this.$route.params.id, 10);
      this.grade = this.userDetails.organization.find(
          (x) => x.type === "grade"
        )
        ? this.userDetails.organization
              .find((x) => x.type === "grade")
              .name.split("-")[0]
          : 0;
      this.title = 'myEDULIBRARY';
      this.setData();
    },
    methods: {
      ...mapActions(['getPackages']),
      search(text) {
        if (!text) {
          this.searchText = '';
          this.setPackages();
        } else {
          this.searchText = text.toLocaleLowerCase('tr-TR').trim();
          this.setPackages();
        }
      },
      setData() {
        this.getData(1);
      },
      setPackages() {
        /*eslint-disable*/
        const collectionId = parseInt(this.$route.params.id, 10);
        this.scormData = this.searchData
                                    .filter(x => this.searchText ? x.name.toLocaleLowerCase('tr-TR').includes(this.searchText) :
                                    true)
                                    .filter(x => Number(this.selectedTheme) !== 0 ? (x.scorm_theme !== null &&
                                                      x.scorm_theme.id === Number(this.selectedTheme)) :
                                                      true)
                                    .filter(x => Number(this.selectedCategory) !== 0 ? (x.scorm_category !== null &&
                                                      x.scorm_category.id === Number(this.selectedCategory)) :
                                                      true);
        this.fillDropdowns();
      },
      fillDropdowns() {
        //THEME DDL
        this.themes = _.uniqBy(this.scormData, 'scorm_theme.name').filter(x => x.scorm_theme && x.scorm_theme.id !== null).map(x => x.scorm_theme);
        this.themesOptString = '';
        this.themesOptString += '<option value="0">All Themes</option>';
        _.orderBy(this.themes, 'name').forEach((theme) => {
          const opt = `<option value='${theme.id}'>${theme.name}</option>`;
          this.themesOptString += opt;
        });
        //CATEGORY DDL
        this.categories = _.uniqBy(this.scormData, 'scorm_category.name')
                                  .filter(x => x.scorm_category && x.scorm_category.id !== null)
                                  .map(x => x.scorm_category);
        this.categoriesOptString = '';
        this.categoriesOptString += '<option value="0">All Categories</option>';
        _.orderBy(this.categories, 'name').forEach((category) => {
          const opt = `<option value='${category.id}'>${category.name}</option>`;
          this.categoriesOptString += opt;
        });
      },
      goToPage(page) {
        return null;
      },
      async getData(page) {
        this.ready = false;
        const collectionId = parseInt(this.$route.params.id, 10);
        const school_id = this.userDetails.organization.find(x => x.type === 'main').id;
        await this.userDetails.organization.filter(y => y.type === "grade").forEach(element => {
          let length = this.libraryShowClasses.filter(x => x === element.id).length;
          if(length > 0){
             this.gradeReady = true;
          }
        });
        if(school_id === 48710 || school_id === 3432 || school_id === 66238 || this.gradeReady === true){ //Piri Reis Üniversitesi & Okyanus Koleji & Yıldız Koleji 99/A
          const res = await services.getPiriScorms(page);
          this.pagination = res.pagination;
          this.searchData = res.data;
          this.scormData = res.data;
        }else if(school_id === 3432 && this.grade === 6){
        // console.log('grade', this.grade);
          const res = await services.getPiriScorms(page);
          this.pagination = res.pagination;
          this.searchData = res.data;
          this.scormData = res.data;
        }
        else{
          const res = await services.getLibraryScorms(0, page, 'reader');
          this.pagination = res.pagination;
          this.searchData = res.data;
          this.scormData = res.data;
        }
        
        this.fillDropdowns();
        setTimeout(() => {
          this.ready = true;
        }, 1000);
      },
    },
    watch: {
      selectedTheme() {
        this.setPackages();
      },
      selectedCategory() {
        this.setPackages();
      },
      $route() {
        this.setData();
      },
    },
  };

</script>

<style src='./style.less' lang='less' module/>
