<template>
  <div></div>
</template>

<script>
import tools from '@/tools';
import config from '../../config';

export default {
  mounted() {
    this.logout();
  },
  methods: {
    logout() {
      tools.cookie.set(config.global_key.tokenName, '', 0);
      tools.cookie.set(config.global_key.mcourserTokenName, '', 0);
      localStorage.clear();
      window.location = '/';
    },
  }

}
</script>

<style>

</style>