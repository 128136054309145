<template>
  <div :title="scormDesc" class="card" :class="$style.divCard">
    <div :class="$style.customCard" @click="goToContent()">
      <div class="row" :class="$style.topCard">
        <div class="col-md-12" style="width:100%; max-height: 185px; overflow: hidden;">
          <img :src="imgpath" alt="Content Cover" style="width:100%" onerror="this.src='https://cdn0.iconfinder.com/data/icons/seo-smart-pack/128/grey_new_seo-37-512.png'">
        </div>
      </div>
      <div class="col-md-12" style="display:flex; flex-direction:column;">
        <h4 :class="$style.pathTitle" v-if="roleType == 'teacher' && isHistory == true" > Already assigned </h4>
        <h4 :class="$style.pathTitle" v-if="contentType == 'scorm' && roleType == 'student'" >    {{ collPath }} </h4>
        <h4 :class="$style.pathTitle" v-if="contentType == 'details' && roleType == 'teacher'" >  {{ collPath }} </h4>
        <vue-custom-tooltip :label="scormTitle ? scormTitle : title" position="is-bottom">
        <h1 :class="$style.contentTitle">{{ scormTitle ? scormTitle : title }}</h1>
        </vue-custom-tooltip>  
        <div class="row justify-content-center">
          <div>
            <span :class="$style.divState" v-if="stateName === 'Not Started'" class="badge badge-light">{{stateName}}</span>
            <span :class="$style.divState" v-else-if="stateName === 'Ongoing'" class="badge badge-info">{{stateName}}</span>
            <span :class="$style.divState" v-else-if="stateName === 'Completed'" class="badge badge-success">{{stateName}}</span>
            <span :class="$style.divState" v-else-if="stateName === 'Expired'" class="badge badge-danger">{{stateName}}</span>
          </div>
        </div>
      </div>
        <div class="row justify-content-center" v-if="contentType == 'scorm' && title" :class="$style.progressRow">
          <div class="col-md-12">
            <div class="progress">
              <div class="progress-bar progress-bar-striped" role="progressbar" :style="this.styleObject" aria-valuemin="10"
                aria-valuemax="100">{{ score ? Math.floor(score) : 0}}%</div>
            </div>
          </div>
          <!-- <div class="col">
            <h4 class="display-5">
              {{ score }}%
            </h4>
          </div> -->
        </div>
      <div class="row d-flex justify-content-bottom align-items-center mt-3" :class="$style.bottomCard" v-if="date">
        <!-- <span v-if="this.$store.state.roleType === 'student' && score" :class='[$style.customBadge, $style.customBadgeFirst]' class="col-lg-5 offset-lg-1 col-md-5 offset-md-1 col-sm-5 offset-sm-1 col-xs-5 offset-xs-1 badge badge-primary">Score</span> -->
        <!-- <span v-if="this.$store.state.roleType === 'student' && score" :class='$style.customBadge' class="col-lg-6 col-md-5 col-sm-5 col-xs-6 badge">{{parseInt(score)}}%</span> -->
        <span v-if="this.$store.state.roleType === 'student' && this.packageType === 'assignment'" :class='[$style.customBadge, $style.customBadgeFirst]' class="col-lg-5 offset-lg-1 col-md-5 offset-md-1 col-sm-5  offset-sm-1  col-xs-5  offset-xs-1  badge badge-info">Due Date</span>
        <span v-else-if="date" :class='[$style.customBadge, $style.customBadgeFirst]' class="col-lg-5 offset-lg-1 col-md-5 offset-md-1 col-sm-5  offset-sm-1  col-xs-5  offset-xs-1  badge badge-info">Due Date</span>
        <span v-if="date" :class='$style.customBadge' class="col-lg-6 col-md-5 col-sm-5 col-xs-5 badge">{{ convertDate(date) }}</span>
        <!-- <div v-if="this.roleType === 'teacher'" class="col-md-6 col-sm-6">
          <button v-if="this.userDetails.lang === 'de'" class="btn" :class="$style.btnPreview">Vorschau</button>
          <button v-else class="btn" :class="$style.btnPreview">Preview</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import tippy from 'tippy.js';

  export default {
    name: 'card',
    data() {
      return {
        routerObj: {},
        styleObject: {
          width: '',
          color: '#fff',
        },
        scoreCard: 0,
      };
    },
    props: [
      'title',
      'imgpath',
      'score',
      'date',
      'contentType',
      'id',
      'packageType',
      'stateName',
      'scormTitle',
      'scormCode',
      'scormDesc',
      'linkUrl',
      'packageId',
      'coverImageHeight',
      'assignmentId',
      'communityType',
      'collPath',
      'isHistory',
    ],
    mounted() {
      this.setRouterObject();
      this.setCoverImageHeight();
      this.setScoreProgess();
    },
    updated() {
      this.setRouterObject();
      this.setCoverImageHeight();
    },
    computed: {
      ...mapState(['roleType', 'userDetails']),
    },
    methods: {
      goToContent() {
        if (this.contentType === 'link') {
          window.open(this.linkUrl, '_blank');
        } else if (this.contentType === 'code') {
          window.open(`https://content.ueslms.com/scorms/${this.linkUrl}/index_new.html`, '_blank');
        } else if (this.contentType === 'details') {
          this.routerObj = {
            name: 'assignmentDetails',
            params: { id: this.assignmentId, community: this.communityType },
          };
          this.$router.push(this.routerObj);
        } else {
          this.$router.push(this.routerObj);
        }
      },
      setRouterObject() {
        if (this.contentType === 'scorm') {
          tippy('[title]');
          if (this.roleType === 'student') {
            if (this.date && !this.linkUrl) {
              this.routerObj = {
                name: 'assignmentDetails',
                params: { id: this.packageId, community: 'individual' },
              };
            } else {
              this.routerObj = {
                name: this.contentType,
                params: {
                  id: this.id,
                  packageId: this.packageId,
                  scormTitle: this.scormTitle,
                  scormDesc: this.scormDesc,
                  scormCode: this.scormCode,
                  packageType: null,
                  timestamp: null,
                },
              };
            }
          } else if (this.roleType === 'teacher') {
            this.routerObj = {
              name: this.contentType,
              params: {
                id: this.id,
                packageId: this.packageId,
                scormTitle: this.scormTitle,
                scormDesc: this.scormDesc,
                scormCode: this.scormCode,
                packageType: null,
                timestamp: null,
              },
            };
          }
        } else if (this.contentType === 'free') {
          this.routerObj = {
            name: 'scorm',
            params: {
              id: this.id,
              packageId: this.packageId,
              scormTitle: this.scormTitle,
              scormDesc: this.scormDesc,
              scormCode: this.scormCode,
              timestamp: null,
            },
          };
        } else {
          this.routerObj = {
            name: this.contentType === 'collectionsPackage' ? 'collections' : this.contentType,
            params: {
              id: this.id,
              searchList: this.contentType === 'collectionsPackage' ? 'packages' : 'collections',
            },
          };
        }
      },
      setCoverImageHeight() {
        if (this.coverImageHeight) {
          const covers = document.getElementsByClassName('cardCoverImg');
          for (const item of covers) {  //eslint-disable-line
            item.style.minHeight = this.coverImageHeight;
            item.style.width = 'auto';
          }
        }
      },
      convertDate(inputFormat) {
        function pad(s) {
          return (s < 10) ? `0${s}` : s;
        }
        const fixDateForAllBrowsers = inputFormat.replace(/-/g, '/');
        const d = new Date(fixDateForAllBrowsers);
        const first = [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-');
        // const second = [pad(d.getHours()), pad(d.getMinutes() + 1), d.getSeconds()].join(':');
        return `${first}`;
      },
      setScoreProgess() {
        if(this.score < 5){
          this.styleObject.width = `10%`;
          // this.styleObject.width = `${this.score}%`;
        }else{
          this.styleObject.width = `${this.score}%`;
        }
      },
    },
  };

</script>

<style src='./style.less' lang='less' module/>
