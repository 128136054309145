<template>
  <div>
      <div id="app">
        <router-view/>
        <NlTawk></NlTawk>
      </div>
  </div>
</template>

<script>
require('@/assets/css/style.less');
import NlTawk from '@/components/NlTawk';

export default {
  name: 'app',
  components: {
      NlTawk,
  },
}
</script>

<style>


</style>
