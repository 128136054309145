<template>
  <section>
    <HeaderCustom></HeaderCustom>
    <div v-if="this.assignmentType == 'individual'" class="container" :class="$style.customContainer">
      <div class="jumbotron" :class="$style.customJumbotron">
        <div class="row align-items-center">
          <div class="col">
            <h2 class="display-5">{{this.assignmentData.name}}</h2>
          </div>
          <div class="col" v-if="roleType === 'student' && timeLesson()">
            <router-link :to="routerObj">
              <button class="btn btn-default float-right" :class="$style.heart">Go to Lesson</button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="jumbotron" :class="$style.customJumbotronViceVersa">
        <div class="row">
          <div class="col" :class="$style.divNames">
            <h2 class="display-5">{{this.assignmentData.assignment_name}}</h2>
            <p class="lead">{{this.assignmentData.assignment_desc ? this.assignmentData.assignment_desc : 'There is no description.'}}</p>
          </div>
          <div class="col">
            <p class="lead">
              <i v-if="this.assignmentData.state_name === 'Not Started'" class="fa fa-stop-circle fa-1x" aria-hidden="true" :class="$style.icon"></i>
              <i v-else-if="this.assignmentData.state_name === 'Ongoing'" class="fa fa-play-circle fa-1x" aria-hidden="true" :class="$style.icon"></i>
              <i v-else-if="this.assignmentData.state_name === 'Completed'" class="fa fa-check-circle fa-1x" aria-hidden="true" :class="$style.icon"></i>
              <i v-else-if="this.assignmentData.state_name === 'Expired'" class="fa fa-lock fa-1x" aria-hidden="true" :class="$style.icon"></i>
              <span>{{this.assignmentData.state_name}}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="jumbotron" :class="$style.customJumbotron">
        <h6 class="display-5">Dates</h6>
        <div class="row">
          <div class="col" :class="$style.dateColumn">
            <span class="badge badge-light">Create Date</span>
            <span class="badge badge-secondary">{{this.assignmentData.create_date}}
            </span>
          </div>
          <div class="col" :class="$style.hrColumn">
            <hr>
          </div>
          <div class="col" :class="$style.dateColumn">
            <span class="badge badge-light">Start Date</span>
            <span class="badge badge-secondary">{{convertDate(this.assignmentData.start_date)}}</span>
          </div>
          <div class="col" :class="$style.hrColumn">
            <hr>
          </div>
          <div class="col" :class="$style.dateColumn">
            <span class="badge badge-light">Due Date</span>
            <span class="badge badge-secondary">{{convertDate(this.assignmentData.end_date)}}</span>
          </div>
        </div>
      </div>
      <div class="jumbotron" :class="$style.customJumbotronViceVersa">
        <h6 class="display-5">Score</h6>
        <div class="row">
          <div class="col">
            <div class="progress">
              <div class="progress-bar progress-bar-striped" role="progressbar" :style="this.styleObject" aria-valuemin="0"
                aria-valuemax="100"></div>
            </div>
          </div>
          <div class="col">
            <h1 class="display-5">
              {{ready ? Math.floor(scormScores.total_score) : 0}}%
            </h1>
          </div>
        </div>
      </div>
      <div class="jumbotron" :class="$style.customJumbotron">
        <div class="row mt-3">
          <div class="col-md-3 text-center">
            <h6 :class="$style.txtLessonName">{{this.assignmentData.scorm.scorm_name}}</h6>
            <img :class="$style.lessonImg" :src="this.assignmentData.scorm.scorm_image">
          </div>
          <div class="col-md-8">
            <h6 class="text-center" :class="$style.txtLessonName">Description</h6>
            <hr>
            <i>{{this.assignmentData.scorm.scorm_desc ? this.assignmentData.scorm.scorm_desc : 'Lesson has no description.'}}</i>
          </div>
        </div>
      </div>
      <div class="jumbotron" :class="$style.customJumbotronViceVersa">
          <div v-if="!ready" :class="$style.lmsLoading"></div>
          <div v-else class="table-responsive">
            <table class="table table-hover table-bordered" :class="$style.customTable">
              <thead>
                <tr>
                  <th>Lesson</th>
                  <th>Score</th>
                  <th>Checks</th>
                  <th>Errors</th>
                  <th>Mistakes</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Total
                  </td>
                  <td>{{ready ? Math.floor(scormScores.total_score) : 0}}%</td>
                  <td>{{this.assignmentData.scorm.scorm_scores.total_checks_count}}</td>
                  <td>{{this.assignmentData.scorm.scorm_scores.total_errors_count}}</td>
                  <td>{{this.assignmentData.scorm.scorm_scores.total_mistake_count}}</td>
                  <td>{{ready ? millisToMinutesAndSeconds(scormScores.total_time) : 0}}
                  </td>
                </tr>
                <tr>
                  <td colspan="6">Activities</td>
                </tr>
              </tbody>
              <tbody v-if="this.paginatedResults.length === 0">
                <tr>
                  <td colspan="6">
                    There is no activity.
                  </td>
                </tr>
              </tbody>
              <tbody v-else v-for="(item,i) in this.paginatedResults" :key="i">

                <tr>
                  <td>{{item.page_name}}</td>
                  <td>{{item.score * 100}}%</td>
                  <td>{{item.checks_count}}</td>
                  <td>{{item.errors_count}}</td>
                  <td>{{item.mistake_count}}</td>
                  <td>{{millisToMinutesAndSeconds(item.time)}}
                  </td>

                </tr>

              </tbody>
            </table>
          </div>
      </div>
    </div>
    <div v-else-if="this.assignmentType == 'group'" class="container" :class="$style.customContainer">
      <div class="jumbotron" :class="$style.customJumbotron">
        <span>Title</span>
        <h2 class="display-5">{{this.assignmentData.assignment_name}}</h2>
        <hr>
        <span>Description</span>
        <h2 class="display-5">{{this.assignmentData.assignment_desc ? this.assignmentData.assignment_desc : 'There is no description.' }}</h2>
      </div>
      <div class="jumbotron" :class="$style.customJumbotronViceVersa">
        <div class="row">
          <div class="col" :class="$style.divNames">
            <span>Group Name</span>
            <p class="lead display-4">{{this.assignmentData.name}}</p>
          </div>
          <div class="col" :class="$style.divGroupLessonIcon">
            <div class="row">
              <div class="col-md-12"><span>{{this.assignmentData.scorm.scorm_name}}</span></div>
              <div class="col-md-12"><img :class="$style.lessonImg" :src="this.assignmentData.scorm.scorm_image"></div>
            </div>
          </div>
          <div class="col">
            <span>Average Score</span>
            <h1 class="display-5">
             {{ ready ? Math.floor(this.scormScores.score) : 0}}%
            </h1>
          </div>
        </div>
      </div>
      <div class="jumbotron" :class="$style.customJumbotron">
        <h6 class="display-5">Dates</h6>
        <div class="row">
          <div class="col" :class="$style.dateColumn">
            <span class="badge badge-light">Create Date</span>
            <span class="badge badge-secondary">{{convertDate(this.assignmentData.create_date)}}
            </span>
          </div>
          <div class="col" :class="$style.hrColumn">
            <hr>
          </div>
          <div class="col" :class="$style.dateColumn">
            <span class="badge badge-light">Start Date</span>
            <span class="badge badge-secondary">{{convertDate(this.assignmentData.start_date)}}</span>
          </div>
          <div class="col" :class="$style.hrColumn">
            <hr>
          </div>
          <div class="col" :class="$style.dateColumn">
            <span class="badge badge-light">Due Date</span>
            <span class="badge badge-secondary">{{convertDate(this.assignmentData.end_date)}}</span>
          </div>
        </div>
      </div>
      <div class="jumbotron" :class="$style.customJumbotronViceVersa">
        <div v-if="!ready" :class="$style.lmsLoading"></div>
        <div v-else class="table-responsive">
          <table class="table table-hover table-bordered" :class="$style.customTable">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>State</th>
                <th>Score</th>
                <th>Checks</th>
                <th>Errors</th>
                <th>Mistakes</th>
                <th>Time</th>
                <!-- <th></th> -->
              </tr>
            </thead>
            <tbody v-for="(item,i) in paginatedResults" :key="i">
              <tr>
                <td>{{i+1}}</td>
                <td>{{item.name}}</td>
                <td>{{assignmentData.state_name}}</td>
                <td>{{Math.floor(item.scorm_scores.total_score)}}%</td>
                <td>{{item.scorm_scores.total_checks_count}}</td>
                <td>{{item.scorm_scores.total_errors_count}}</td>
                <td>{{item.scorm_scores.total_mistake_count}}</td>
                <td>{{millisToMinutesAndSeconds(item.scorm_scores.total_time) }}</td>
                <!-- <td>
                  <router-link :to="{ name: 'assignmentDetails', params: { 
                    id: assignmentData.assignment_id, 
                    community: 'individual', 
                    userId: item.user_id 
                  }}">
                    <button class="btn" :class="$style.btnDetails" title="Details">
                      <i class="fa fa-info-circle fa-1x"></i>
                    </button>
                  </router-link>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HeaderCustom from '@/components/HeaderCustom';
// import InnerLoading from '@/components/InnerLoading';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

import contentService from '@/services/content';

export default {
  name: 'assignmentDetails',
  data() {
    return {
      ready: false,
      assignmentData: {},
      data: {},
      routerObj: {},
      paginatedResults: [],
      styleObject: {
        width: '',
      },
      assignmentType: '',
      paginated_results: [],
      scormScores: null,
    };
  },
  computed: {
    ...mapState(['assignmentss', 'assignments', 'roleType', 'userDetails']),
  },
  methods: {
    ...mapActions(['getLmsAssignments']),
    millisToMinutesAndSeconds(millis) {
      let minutes = Math.floor(millis / 60000);
      let seconds = ((millis % 60000) / 1000).toFixed(0);
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (seconds < 10) {
        seconds = `${minutes}`;
      }
      return `${minutes}:${seconds}`;
    },
    async setIndividualAssignment() {
      const assignmentId = this.$route.params.id;
      await this.getLmsAssignments();
      this.assignmentData = this.assignments.find(x => x.assignment_id === Number(assignmentId))
        || this.assignmentss.find(x => x.assignment_id === Number(assignmentId));
      this.routerObj = {
            name: 'scorm',
            params: {
              id: this.assignmentData.scorm.scorm_id,
              packageId: this.assignmentData.assignment_id,
              scormTitle: this.assignmentData.scorm.scorm_name,
              scormDesc: this.assignmentData.scorm.scorm_desc,
              scormCode: this.assignmentData.scorm.scorm_code,
              packageType: 'assignment',
              timestamp: null,
            },
      };
      this.assignmentType = 'individual';
      const paginatedScores = await contentService.getPaginatedScores(assignmentId);
      if (paginatedScores.success) {
        if (paginatedScores.data.assignment_type === 'group') {
          if (this.roleType === 'student') {
            this.paginatedResults = paginatedScores.data.users.find(x => x.user_id === this.userDetails.user_id).scorm_scores.paginated_results;
            this.scormScores = paginatedScores.data.users.find(x => x.user_id === this.userDetails.user_id).scorm_scores;
          } else if (this.roleType === 'teacher'){
            this.paginatedResults = paginatedScores.data.users.find(x => x.user_id === Number(this.$route.params.userId)).scorm_scores.paginated_results;
            this.scormScores = paginatedScores.data.users.find(x => x.user_id === Number(this.$route.params.userId)).scorm_scores;
          }
        } else {
          this.paginatedResults = paginatedScores.data.users.scorm_scores.paginated_results;
          this.scormScores = paginatedScores.data.users.scorm_scores;
        }
      }
      const now = new Date();
      //? Eğer Group Assignment detayından öğrencinin o ödevdeki bireysel detayına gidilirse
      if(this.assignmentData.assignment_type === 'group') {
        let userId = 0;
        if(this.$route.params.userId) {
          userId = Number(this.$route.params.userId);
        } else {
          userId = this.$store.state.userDetails.user_id;
        }
        if (paginatedScores.success) {
          const userScore = paginatedScores.data.users.find(x => x.user_id === userId);
          if (new Date(this.assignmentData.end_date).getTime() < now.getTime()) {
            this.assignmentData.state_name = 'Expired';
          }else if (userScore.scorm_scores.total_score === 100) {
            this.assignmentData.state_name = 'Completed';
          } else if (userScore.scorm_scores.total_score === 0) {  //eslint-disable-line
            this.assignmentData.state_name = 'Not Started';
          } else {
            this.assignmentData.state_name = 'Ongoing';
          }
          this.assignmentData.name = userScore.name;
          this.assignmentData.scorm.scorm_scores = userScore.scorm_scores;
          this.paginatedResults = userScore.scorm_scores.paginated_results;
        }
      } else if (new Date(this.assignmentData.end_date).getTime() < now.getTime()) {
        this.assignmentData.state_name = 'Expired';
      }
      this.styleObject.width = `${this.assignmentData.scorm.scorm_scores.total_score}%`;
      this.ready = true;
    },
    async setGroupAsssignment() {
      const assignmentId = parseInt(this.$route.params.id, 10);
      // await this.getLmsAssignments();
      this.assignmentData = this.assignmentss.find(x => x.assignment_id === assignmentId);
      this.assignmentType = 'group';
      const paginatedScores = await contentService.getPaginatedScores(assignmentId);
      if (paginatedScores.success) {
        this.paginatedResults = paginatedScores.data.users;
        let avgScore = 0;
        for (const item of this.paginatedResults) {
          avgScore += item.scorm_scores.total_score;
        }
        avgScore /= this.paginatedResults.length;
        this.scormScores = {};
        this.scormScores.score = avgScore;
      }
      this.ready = true;
    },
    convertDate(inputFormat) {
        // console.log('mmmmm',moment().format('YYYY-mm-DD hh:mm:ss'));
        
        const test = moment(inputFormat).valueOf();
        const finalFormat = moment(test).add(1,"minutes").format('DD-MM-YYYY HH:mm:ss');
        return finalFormat;
        // function pad(s) {
        //   return (s < 10) ? `0${s}` : s;
        // }
        // const fixDateForAllBrowsers = inputFormat.replace(/-/g, '/');
        // const d = new Date(fixDateForAllBrowsers);
        // const first = [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-');
        // const second = [pad(d.getHours()), pad(d.getMinutes() + 1), d.getSeconds() < 10 ? `0${d.getSeconds()}`: `${d.getSeconds()}`].join(':');
        // return `${first} ${second}`;
    },
    timeLesson() {
      return (moment().valueOf() < moment(this.assignmentData.end_date).valueOf()) && (moment().valueOf() > moment(this.assignmentData.start_date).valueOf());
    },
  },
  async beforeMount() {
    if (this.$route.params.community === 'individual') {
      this.setIndividualAssignment();
    } else if (this.$route.params.community === 'group') {
      this.setGroupAsssignment();
    }
  },
  components: {
    HeaderCustom,
    // InnerLoading,
  },
  watch: {
    async $route() {
      if (this.$route.params.community === 'individual') {
        await this.setIndividualAssignment();
      } else if (this.$route.params.community === 'group') {
        await this.setGroupAsssignment();
      }
    },
  },
};
</script>
<style scoped>
.badge {
  float: left;
  width: 100%;
  border-radius: 0px !important;
}

hr {
  border-top: 1px solid white !important;
}
</style>

<style src='./style.less' lang='less' module/>
